
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentoringSessionCompletedRating",
  props: {
    ratings: Object
  },
  data() {
    return {
      maxRating: 10
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    getKey(key: string) {
      return key.replace("_feedback", "");
    }
  }
});
