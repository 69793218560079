
import Vue from "vue";
import BadgesInfoModal from "@/components/Modals/BadgesInfoModal.vue";

export default Vue.extend({
  name: "MentoringSessionMentorRating",
  components: {
    BadgesInfoModal
  },
  props: {
    ratings: {
      type: Object
    }
  },
  data() {
    return {};
  }
});
