
import Vue from "vue";
import { Session } from "@/types/interfaces";
import Content from "@/components/SessionContent/Content.vue";
import MentoringSessionChat from "@/components/Session/MentoringSessionChat.vue";
import MentoringSessionRatingLoading from "@/components/Session/MentoringSessionRatingLoading.vue";
import MentoringSessionMentorRating from "@/components/Session/MentoringSessionMentorRating.vue";
import MentoringSessionCompletedRating from "@/components/Session/MentoringSessionCompletedRating.vue";

export default Vue.extend({
  name: "SessionTabs",
  components: {
    MentoringSessionCompletedRating,
    MentoringSessionMentorRating,
    MentoringSessionRatingLoading,
    MentoringSessionChat,
    Content
  },
  props: {
    loading: Boolean,
    session: {
      type: Object as () => Session
    },
    ratings: Object,
    profile: Object
  },
  data() {
    return {
      contentList: {}
    };
  },
  methods: {
    onContentTab() {
      //@ts-ignore
      this.$refs["content-tab"].getSessionContent();
    }
  }
});
